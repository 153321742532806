import { useEffect, useState } from "react";
// for work_images
import work1 from "../assets/images/work_images/1.jpg";
import work2 from "../assets/images/work_images/2.jpg";
import work3 from "../assets/images/work_images/3.jpg";
import work4 from "../assets/images/work_images/4.jpg";
import work5 from "../assets/images/work_images/5.jpg";
import work6 from "../assets/images/work_images/6.jpg";
import work7 from "../assets/images/work_images/7.jpg";
import work8 from "../assets/images/work_images/8.jpg";
import work9 from "../assets/images/work_images/9.jpg";
// works small images
import workSmall1 from "../assets/images/work_images/small/1.jpg";
import workSmall2 from "../assets/images/work_images/small/2.jpg";
import workSmall3 from "../assets/images/work_images/small/3.jpg";
import workSmall4 from "../assets/images/work_images/small/4.jpg";
import workSmall5 from "../assets/images/work_images/small/5.jpg";
import workSmall6 from "../assets/images/work_images/small/6.jpg";
import workSmall7 from "../assets/images/work_images/small/7.jpg";
import workSmall8 from "../assets/images/work_images/small/8.jpg";
import workSmall9 from "../assets/images/work_images/small/9.jpg";
// blog post images
import blog6 from "../assets/images/blog_images/6.jpg";
import blog4 from "../assets/images/blog_images/4.jpg";
import blog2 from "../assets/images/blog_images/2.jpg";
import blog1 from "../assets/images/blog_images/1.jpg";
import blog3 from "../assets/images/blog_images/3.jpg";
import blog5 from "../assets/images/blog_images/5.jpg";
// blog image small
import blogSmall6 from "../assets/images/blog_images/small/6.jpg";
import blogSmall4 from "../assets/images/blog_images/small/4.jpg";
import blogSmall2 from "../assets/images/blog_images/small/2.jpg";
import blogSmall1 from "../assets/images/blog_images/small/1.jpg";
import blogSmall3 from "../assets/images/blog_images/small/3.jpg";
import blogSmall5 from "../assets/images/blog_images/small/5.jpg";

import img1 from "../assets/images/slider/brand-1.png";
import img2 from "../assets/images/slider/brand-2.png";
import img3 from "../assets/images/slider/brand-3.png";
import img4 from "../assets/images/slider/brand-4.png";
import img5 from "../assets/images/slider/brand-5.png";
import img6 from "../assets/images/slider/brand-6.webp";
import img7 from "../assets/images/slider/brand-7.png";
//  icon use as img here
import icon from "../assets/images/icons/icon-1.svg";
import icon1 from "../assets/images/icons/icon-2.svg";
import icon2 from "../assets/images/icons/icon-3.svg";
import icon3 from "../assets/images/icons/icon-4.svg";

// contact image
import iconPhone from "../assets/images/contact/phone-call 1.png";
import iconEmail from "../assets/images/contact/email 1.png";
import iconMap from "../assets/images/contact/map 1.png";
import { CgNotes } from "react-icons/cg";
import { FaBlogger, FaRegUser } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { FiCodesandbox } from "react-icons/fi";
import { RiContactsBookLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem("theme"));
  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem("theme");

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem("theme", "light");
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "light");
      setLocal("light");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // fillter portfilo data
  const handleData = (text) => {
    if (text === "All") {
      setData(workItems);
    } else {
      const findData = workItems.filter((item) => item.tag === text);
      setData(findData);
    }
  };

  // find items for portfilo  modal open
  const handleModelData = (id) => {
    const find = workItems.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // find items for Blogs  modal open
  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Elements for protfilo section
  const workItems = [
    {
      id: "1",
      tag: "Personnel",
      title: "Méteo",
      img: work1,
      imgSmall: workSmall1,
      bg: "#FFF0F0",
      client: "Envato",
      langages: "JS",
      link: "https://github.com/a-moumou/Meteo",
      linkText: "www.github.com",
      description:
        "Cette application de météo, développée en utilisant JavaScript, offre aux utilisateurs une expérience simple et intuitive pour obtenir des prévisions météorologiques. Elle propose deux options distinctes pour accéder aux informations. La première option utilise la localisation actuelle de l'utilisateur, détectée automatiquement, pour fournir des prévisions précises et en temps réel. Cette fonctionnalité est idéale pour ceux qui souhaitent connaître rapidement la météo locale sans aucune saisie supplémentaire. La deuxième option permet aux utilisateurs d'entrer manuellement le nom d'une ville dans un champ de saisie, offrant ainsi la possibilité de consulter les conditions météorologiques de n'importe quelle ville dans le monde. Grâce à cette double approche, l'application se distingue par sa flexibilité et son utilité, répondant efficacement aux divers besoins des utilisateurs.",
    },
    {
      id: "2",
      tag: "Professionnel",
      title: "DPO Drive",
      img: work2,
      imgSmall: workSmall2,
      bg: "#FFF3FC",
      client: "Themeforest",
      langages: "HTML, CSS, JS, Symfony ",
      link: "",
      linkText: "Aucun",
      description:
        "  Lors de mon stage chez SAS MANAI, j'ai participé à un projet de 8 semaines visant à optimiser les processus de gestion. J'ai contribué à la migration de l'application MSQualité du PHP pur vers Symfony et à la création d'une liaison entre les systèmes Dolibarr et MSQualité pour synchroniser les données clients. J'ai développé une page permettant de choisir un établissement, un contact et un modèle de document, avec une génération automatique des informations. J'ai également automatisé la gestion des frais, réduisant ainsi les erreurs et le temps consacré à cette tâche. Enfin, j'ai conçu un site web pour générer des fichiers Excel à partir des notes de frais, simplifiant la gestion et l'analyse des données.",
    },
    {
      id: "3",
      tag: "Personnel",
      title: "SHIFUMI",
      img: work3,
      imgSmall: workSmall3,
      bg: "#FFF0F0",
      client: "Freepik",
      langages: "JS",
      link: "https://github.com/a-moumou/shifumi",
      linkText: "www.github.com",
      description:
        "  J'ai développé un jeu de Shifoumi en JavaScript où le joueur peut jouer contre l'ordinateur. Le jeu offre au joueur la possibilité de choisir entre pierre, papier ou ciseaux, et l'ordinateur fait également un choix aléatoire parmi ces options. Le programme détermine ensuite le gagnant en comparant les choix du joueur et de l'ordinateur selon les règles classiques du Shifoumi. Ce projet est une excellente démonstration de l'utilisation de JavaScript pour créer des interactions dynamiques et amusantes sur une page web.",
    },
    {
      id: "4",
      tag: "École",
      title: "Icarus",
      img: work4,
      imgSmall: workSmall4,
      bg: "#E9FAFF",
      langages: "HTML ,CSS ,JS",
      link: "https://github.com/a-moumou/Icarus",
      linkText: "www.github.com",
      description:
        "  Pour mon premier projet (AP1), j'ai créé un site pour présenter mes initiatives. J'ai défini le nom et les valeurs de l'entreprise, conçu un logo, élaboré une charte graphique et imaginé un slogan. J'ai également décrit les produits et services, précisé le statut juridique, et réalisé une étude de la concurrence. Enfin, j'ai développé le site web en HTML, CSS et JavaScript, tout en assurant son référencement sur les moteurs de recherche et LinkedIn.",
       
    },
    
    
  
  
    
    {
      id: "9",
      tag: "Personnel",
      title: "Bibliothéque",
      img: work9,
      imgSmall: workSmall9,
      bg: "#FCF4FF",
      client: "Feepik",
      langages: "Figma",
      link: "https://github.com/SprigganTwelve/biblio",
      linkText: "www.github.com",
      description:
        " J'ai développé avec un partenaire une application de gestion de bibliothèque intégrant l'API Google Books pour afficher des livres en fonction du genre ou du titre saisi par l'utilisateur. J'ai mis en place un système d'inscription et de connexion sécurisé avec chiffrement des mots de passe. Les utilisateurs peuvent ajouter des livres à leur bibliothèque personnelle, enregistrant automatiquement les détails dans la base de données. Ils peuvent également gérer leur bibliothèque (ajout, visualisation, suppression de livres) et mettre à jour leurs informations personnelles à tout moment.",
    },
  ];
  const [data, setData] = useState(workItems);

  // Elements for Blogs section
  const blogsData = [
    {
      id: "1",
      img: blog1,
      imgSmall: blogSmall1,
      title: "lunette virtuelle",
      bg: "#FCF4FF",
      description:
        " ",
    },
    {
      id: "4",
      img: blog4,
      imgSmall: blogSmall4,
      title: "Everything You Need to Know About Web Accessibility.",
      bg: "#EEFBFF",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    
  ];

  // Menu items for Homepage One
  const menuItem = [
    {
      id: "01",
      name: "Home",
      link: "/home/homePage",
      icon: <AiOutlineHome />,
    },
    {
      id: "02",
      name: "About",
      link: "/home/about",
      icon: <FaRegUser />,
    },
    { id:"07",
      name: "BTS",
      link: "/home/bts",
      icon: <FaRegUser />,
  },

    {
      id: "06",
      name: "Resume",
      link: "/home/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Works",
      link: "/home/works",
      icon: <FiCodesandbox />,
    },
    {
      id: "04",
      name: "Veille technologique",
      link: "/home/blogs",
      icon: <FaBlogger />,
    },
    {
      id: "05",
      name: "Contact",
      link: "/home/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Menu items for Homepage Two
  const menuItemTwo = [
    {
      id: "01",
      name: "Accueil",
      link: "/",
      icon: <FaRegUser />,
    },
    {
      id: "02",
      name: "À Propos",
      link: "/resume",
      icon: <CgNotes />,
    },
    
  
    {
      id: "03",
      name: "Projets",
      link: "/works",
      icon: <FiCodesandbox />,
    },
    
    
    {
      id: "05",
      name: "Contact",
      link: "/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Slider image for Clients
  const sliderImg = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
  ];

  // serviceArray items for about page
  const serviceArray = [
    {
      id: "1",
      icon: icon,
      title: "DYNAMIQUE",
      des: "Énergétique, c'est le mot qui me définit.",
      color: "#D566FF",
      bg: "#FCF4FF",
    },
    {
      id: "2",
      icon: icon1,
      title: "CURIEUX",
      des: "J'ai découvert une forme de‎ ‎ ‎ ‎curiosité pour les nouveaux ‎ ‎ ‎ ‎ ‎langages et méthodes de‎ ‎ ‎ ‎  ‎ ‎ ‎ programmation.",
      color: "#DDA10C",
      bg: "#FEFAF0",
    },
    {
      id: "3",
      icon: icon2,
      title: "PERSÉVÉRANT",
      des: "L'obstination peut être longue et fastidieuse, mais qui est ‎ ‎ ‎ ‎ ‎ souvent récompensée.",
      color: "#8774FF",
      bg: "#FCF4FF",
    },
    {
      id: "4",
      icon: icon3,
      title: "Initiativité",
      des: "Capacité à prendre des initiatives et à agir de manière autonome pour atteindre les objectifs.",
      color: "#FF6080",
      bg: "#FFF4F4",
    },
    
  ];

  // educationArray items for Resume page
  const educationArray = [
    {
      id: 1,
      date: "2023 - présent",
      title: "BTS SIO OPTION SLAM",
      place: "IPSSI grande école d'informatique",
      bg: "#FFF4F4",
    },

    {
      id: 2,
      date: "2021-2023 ",
      title: "Baccalauréat scientifique",
      place: "Lycée Debdou",
      bg: "#FFF1FB",
    },

   
  ];
  // experiencesArray items for Resume page
  const experiencesArray = [
    {
      id: 1,
      date: "Mai 2024 - juin 2024",
      title: "DPO DRIVE",
      place: "Développeur Web PHP - Stage",
      bg: "#EEF5FA",
    },

    {
      id: 2,
      date: "Juillet 2022",
      title: "Go Digital ",
      place: "Développeur Full-stack - Stage",
      bg: "#F2F4FF",
    },

  ];
  // awardsArray items for Resume page
  const awardsArray = [
    {
      id: 1,
      date: "2015-2017",
      title: "  Graphic Designer",
      place: "Web Graphy, Los Angeles, CA",
      bg: "#FCF4FF",
    },

    {
      id: 2,
      date: "2014 - 2015",
      title: "Jr. Web Developer",
      place: "Creative Gigs.",
      bg: "#FCF9F2",
    },

    {
      id: 3,
      date: "2015-2017",
      title: "Best Freelancer",
      place: "Fiver & Upwork Level 2 & Top Rated",
      bg: "#FCF4FF",
    },
  ];

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: "01",
      color: "#FF6464",
      name: "HTML5/CSS3",
      number: "85",
    },
    {
      id: "02",
      color: "#9272D4",
      name: "PHP ",
      number: "70",
    },
    {
      id: "03",
      color: "#CA56F2",
      name: "Symfony",
      number: "50",
    },
    {
      id: "04",
      color: "#5185D4",
      name: "JavaScript",
      number: "60",
    },
    {
      id: "05",
      color: "#dda10b",
      name: "ReactJS",
      number: "40",
    },
   
    {
      id: "06",
      color: "#ff6080",
      name: "Tailwind CSS",
      number: "70",
    },
   
    
  ];

  // Personal information for contact pages
  const contactArray = [
    {
      id: "01",
      icon: iconPhone,
      title: "Phone ",
      item1: "+452 666 386",
      item2: "+452 666 386",
      bg: "#FCF4FF",
    },
    {
      id: "02",
      icon: iconEmail,
      title: "Email ",
      item1: "support@gmail.com",
      item2: "example@gmail.com",
      bg: "#EEFBFF",
    },
    {
      id: "03",
      icon: iconMap,
      title: "Address ",
      item1: "Maount View, Oval",
      item2: "Road, New York, USA",
      bg: "#F2F4FF",
    },
  ];

  return {
    handleTheme,
    check,
    local,
    handleData,
    data,
    singleData,
    handleModelData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
    menuItem,
    NavLink,
    menuItemTwo,
    serviceArray,
    sliderImg,
    educationArray,
    experiencesArray,
    awardsArray,
    lineArray,
    contactArray,
  };
};

export default AllData;
