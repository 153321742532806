import React from "react";
import UseData from "../../Hooks/UseData";
import Footer from "../../Share/Footer";
import "../../Share/Style.css";
import SliderCommon from "../../Share/SliderCommon";
import AboutCard from "./AboutCard";
import HomeCard from "../../Share/HomeCard";
import PageTitle from "../../Share/PageTitle";

const AboutTwo = () => {
  const { local, serviceArray } = UseData();

  return (
    <section>
      <PageTitle title="About"></PageTitle>
      {/* End pagetitle */}

      <div className=" lg:rounded-2xl bg-white dark:bg-[#111111]">
        <div data-aos="fade">
          <div className=" pt-12 md:py-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
            {/* About page title */}
            <h2 className="after-effect after:left-80 lg:block hidden">
            À Propos de moi
            </h2>
            <div className="lg:hidden">
              {/* Sidebar personal information for mobile devices */}
              <HomeCard />
              <div className=" md:gap-10 mb-12 md:pt-[30px] items-center lg:hidden ">
                <h2 className="after-effect after:left-52 mb-5">À Propos de moi</h2>
                <div className="col-span-12 space-y-2.5">
                  <div className="lg:mr-16">
                    <p className="text-gray-lite  dark:text-color-910 leading-7">
                    Étudiant dynamique et développeur web passionné, je suis constamment à la 
                    recherche de nouveaux défis techniques à relever. Ma soif d'apprentissage et ma détermination 
                    me poussent à me dépasser sur chaque projet. Actuellement en quête d'une alternance, je souhaite 
                    mettre mon engagement et mes compétences au service d'une entreprise innovante où je pourrai continuer 
                    à évoluer et contribuer activement aux projets web.
                    </p>
                    <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                    N'hésitez pas à parcourir ce portfolio ou à télécharger mon CV pour avoir plus de détails sur mon expérience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:grid grid-cols-12 md:gap-10 pt-4 md:pt-[30px] items-center hidden ">
              <div className="col-span-12 space-y-2.5">
                <div className="lg:mr-16">
                  <p className="text-gray-lite  dark:text-color-910 leading-7">
                  Étudiant dynamique et développeur web passionné, je suis constamment à la 
                  recherche de nouveaux défis techniques à relever. Ma soif d'apprentissage et ma détermination me 
                  poussent à me dépasser sur chaque projet. Actuellement en quête d'une alternance, je souhaite mettre mon
                   engagement et mes compétences au service d'une entreprise innovante où je pourrai continuer à évoluer et
                    contribuer activement aux projets web.
                  </p>
                  <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                  N'hésitez pas à parcourir ce portfolio ou à télécharger mon CV pour avoir plus de détails sur mon expérience.                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* End about descriptions */}

          <div className="  pb-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
            <h3 className="text-[35px] dark:text-white font-medium pb-5">
            Mes Qualités
            </h3>
            <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2 ">
              {/* Experience information  */}

              {serviceArray.map((item) => (
                <AboutCard key={Math.random()} item={item} local={local} />
              ))}
            </div>
          </div>
          {/* service provide end */}

          <div className="px-2 sm:px-5 md:px-10 lg:px-14 ">
            {/* Slick Slider call here  */}
            <SliderCommon />
          </div>

          {/* Common Footer call here */}
          <Footer bg={"#FFFF"} />
        </div>
      </div>
    </section>
  );
};

export default AboutTwo;
